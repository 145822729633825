export const GET_PRODUCTS = 'GET_PRODUCTS';
export const USER_ADDRESSES = 'USER_ADDRESSES';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_CART = 'SET_USER_CART';
export const SET_ORDERS = 'SET_ORDERS';

const initialState = {
  products: [],
  userAddresses: [],
  userProfile: {}, // Initialize as an object if it's a single profile
  cart: [],
  totalQuantity: 0,
  orders:[], // Initialize as an array for cart items
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, products: action.payload };
    case USER_ADDRESSES:
      return { ...state, userAddresses: action.payload };
    case SET_USER_PROFILE:
      return { ...state, userProfile: action.payload };
   
       case SET_ORDERS:
      return { ...state, orders: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
