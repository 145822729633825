import React, { useState, useEffect } from "react";
import axios from "axios";
import UserAccount from "./UserAccount";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "./../../../redux/action";
import { MdMenu } from "react-icons/md";

const ProfileInformation = () => {
  const [user, setUser] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [userAccount, setUserAccount] = useState(true);
  const [userProfile, setUserProfiled] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();

  const userProfilee = useSelector((state) => state.userProfile);
// console.log("userProfilee",userProfilee);
  useEffect(() => {
    const setUserProfile = async () => {
      if (!userId || !token) {
        console.error("User ID or token is missing");
        return; // Prevent the request if userId or token is missing
      }

      try {
        const response = await axios.get(
          `https://uvfolderking.com/suresop/api/user/profile/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Correctly formatted token
            },
          }
        );
        const Data = response.data["User Profile"];
        setUserProfiled({
          firstName: Data.first_name,
          lastName: Data.last_name,
          email: Data.email,
        });
        setMobile(Data.mobile_number);
        // console.table("profile details is ", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized: Invalid token or session expired.");
          // Optional: dispatch a logout or token refresh action
        } else {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    setUserProfile();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (!userId || !token) {
      console.error("User ID or token is missing");
      return;
    }

    try {
      setIsUpdating(true);
      const response = await axios.put(
        `https://uvfolderking.com/suresop/api/user/update/${userId}`,
        {
          first_name: userProfile.firstName,
          last_name: userProfile.lastName,
          email: userProfile.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Profile updated successfully:", response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      setIsUpdating(false);
    }
  };

  function UserAcount() {
    setUserAccount(!userAccount);
  }
  function setValue(e) {
    setUserProfiled({
      ...userProfile,
      [e.target.id]: e.target.value,
    });
    console.log(e.target.id);
  }

  return (
    <div className=" relative bg-gray-100 flex gap-4 ">
      <div>
        {userAccount ? <UserAccount showUserAccount={UserAcount} /> : ""}
      </div>

      <div className="flex-grow w-full  mr-10   rounded-md bg-white p-4">
        <div className="flex justify-end">
          <MdMenu onClick={UserAcount} className="text-[1.5rem] " />
        </div>

        <div className="flex items-center justify-between ">
          <h2 className="text-xl font-semibold pb-5">Personal Information</h2>
        </div>

        <form className="p-4 rounded" onSubmit={handleProfileUpdate}>
          {/* Name Fields */}
          <div className="mb-6 flex flex-col md:flex-row md:gap-4">
            <div>
              <label htmlFor="firstName" className="block text-gray-700 mb-2">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                placeholder="First Name"
                value={userProfile.firstName}
                className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                onChange={setValue}
              />
            </div>

            <div>
              <label htmlFor="lastName" className="block text-gray-700 mb-2">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name"
                value={userProfile.lastName}
                className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                onChange={setValue}
              />
            </div>
          </div>

          {/* Gender Selection */}
          {/* <div className="mb-6">
            <label className="block text-gray-700 mb-2">Your Gender</label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                className="mr-2"
                required
              />
              <label htmlFor="male" className="text-gray-700 mr-4">
                Male
              </label>

              <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                className="mr-2"
                required
              />
              <label htmlFor="female" className="text-gray-700">
                Female
              </label>
            </div>
          </div> */}

          {/* Email Address Field */}
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email Address"
              value={userProfile.email}
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              onChange={setValue}
            />
          </div>

          {/* Mobile Number Field */}
          <div className="mb-6">
            <label htmlFor="mobile" className="block text-gray-700 mb-2">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              value={mobile}
              placeholder="Mobile Number"
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Update Button */}
          <button
            type="submit"
            className="mt-4 bg-[#b22222] text-white p-2 rounded hover:bg-white hover:text-[#b22222] hover:shadow-lg transition duration-200"
          >
            Update Profile
          </button>
        </form>

        {isUpdating && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl w-80 text-center">
              <div className="flex flex-col items-center">
                <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-green-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mt-4">
                  Success
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Your profile is being updated. Please wait while we process
                  your request.
                </p>
              </div>
            </div>
          </div>
        )}
        {/* Account Deletion Warning */}
        <div className="mb-4 mt-5">
          <p className="text-gray-600">
            Deleting your account will remove all your data permanently.
          </p>
          <button
            type="button"
            className="mt-2 text-red-600 hover:underline"
            // onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
        </div>

        {/* FAQs Section */}
        <h2 className="text-xl font-bold mt-10">FAQs</h2>
        <div className="mt-4">
          <div className="mb-4">
            <h3 className="font-semibold">
              What happens when I update my email address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              Your login email id (or mobile number) changes, likewise. You'll
              receive all your account-related communication on your updated
              email address (or mobile number).
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              When will my Flipkart account be updated with the new email
              address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              It happens as soon as you confirm the verification code sent to
              your email (or mobile) and save the changes.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              What happens to my existing Flipkart account when I update my
              email address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              Updating your email address (or mobile number) doesn't invalidate
              your account. Your account remains fully functional. You'll
              continue seeing your order history, saved information, and
              personal details.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              Does my Seller account get affected when I update my email
              address?
            </h3>
            <p className="text-gray-600">
              Flipkart has a 'single sign-on' policy. Any changes will reflect
              in your Seller account also.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;
