import React from 'react';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { RiMoneyDollarCircleLine, Ri24HoursFill, RiSecurePaymentLine } from 'react-icons/ri';

const SuresopSetting = () => {
  return (
    <div className=" bg-red-50 flex flex-wrap justify-around p-4 pb-10 border-t border-gray-200">
      <div className="flex items-center space-x-3">
        <LiaShippingFastSolid className="text-[#b22222] text-[50px]" />
        <div>
        <div><p className="hidden md:inline text-[20px]  font-bold">Free Shipping & Returns</p></div>
        <div><p  className="hidden md:inline">Free Shipping & Returns</p></div></div> 
      
      </div>

      <div className="flex items-center space-x-3">
        <RiMoneyDollarCircleLine className="text-[#b22222] text-[50px]" />
       <div>  <div>
        <p className="hidden md:inline text-[20px] font-bold">Money Back Guarantee</p>
        </div>
        <div>
        <p className="hidden md:inline">Money Back Guarantee</p>
        </div>
        </div>
      </div>

      <div className="flex items-center space-x-3">
        <Ri24HoursFill className="text-[#b22222] text-[50px]" />
        <div> <div>
        <p className="hidden md:inline text-[20px]  font-bold">Online Support 24/7</p>
        </div>
        <div>
        <p className="hidden md:inline">Online Support 24/7</p>
        </div>
        </div>
      </div>

      <div className="flex items-center space-x-3">
        <RiSecurePaymentLine className="text-[#b22222] text-[50px]" />
        <div>
            <div>
        <p className="hidden md:inline text-[20px] font-bold">Secure Payment</p>
        </div>
        <div>
        <p className=" hidden md:inline">Secure Payment</p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SuresopSetting;
