import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">
        Terms and Conditions
      </h1>

      <div className="space-y-8 text-gray-800">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to Suresop. These Terms and Conditions ("Terms") govern your
            use of our website and services. By accessing and using our website,
            you agree to comply with and be bound by these Terms. Please read
            them carefully.
          </p>
        </section>

        {/* Acceptance of Terms */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
          <p>
            By accessing or using our services, you agree to these Terms. If you
            do not agree with these Terms, please refrain from using our
            website.
          </p>
        </section>

        {/* Changes to Terms */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
          <p>
            We reserve the right to modify or update these Terms at any time.
            Any changes will be posted on this page, and the revised Terms will
            take effect immediately upon posting. We encourage you to review
            this page regularly for updates.
          </p>
        </section>

        {/* Use of Website */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Use of Website</h2>
          <p>
            You may use our website and services only for lawful purposes and in
            accordance with these Terms. You agree not to:
          </p>
          <ul className="list-disc pl-5 mt-4 space-y-2">
            <li>
              Engage in any illegal activity or use our website for unlawful
              purposes.
            </li>
            <li>
              Transmit any harmful or offensive content, including viruses or
              malware.
            </li>
            <li>
              Impersonate any person or entity or misrepresent your affiliation
              with any entity.
            </li>
          </ul>
        </section>

        {/* Account Registration */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Account Registration</h2>
          <p>
            To access certain features on our website, you may be required to
            register for an account. You agree to provide accurate, current, and
            complete information during registration and to keep your account
            information updated.
          </p>
          <p>
            You are responsible for maintaining the confidentiality of your
            account login details and agree to notify us immediately of any
            unauthorized access or use of your account.
          </p>
        </section>

        {/* Orders and Payment */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Orders and Payments</h2>
          <p>
            By placing an order on our website, you agree to pay for the items
            purchased and any applicable taxes, shipping, or handling charges.
            We accept payment via various methods, including credit/debit cards
            and third-party payment gateways.
          </p>
          <p>
            We reserve the right to refuse or cancel any order at our
            discretion, including due to product availability, pricing errors,
            or fraudulent activity.
          </p>
        </section>

        {/* Privacy Policy */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
          <p>
            Our Privacy Policy governs how we collect, use, and protect your
            personal information. By using our website, you consent to the
            practices described in our Privacy Policy.
          </p>
          <p>
            To learn more about how we handle your data, please review our
            Privacy Policy.
            <a href="/privacy-policy" className="text-blue-600 hover:underline">
              Click here
            </a>{" "}
            to read it.
          </p>
        </section>

        {/* Limitation of Liability */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Limitation of Liability
          </h2>
          <p>
            To the maximum extent permitted by law, Suresop will not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages arising from your use or inability to use our website or
            services.
          </p>
        </section>

        {/* Governing Law */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
          <p>
            These Terms will be governed by and construed in accordance with the
            laws of [Your Country/State], without regard to its conflict of law
            principles.
          </p>
        </section>

        {/* Contact Information */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at:
          </p>
          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li>
              Email:
              <a
                href="mailto:support@suresop.com"
                className="text-blue-600 hover:underline"
              >
               support@suresop.com
              </a>
            </li>
            <li>Phone: +1 (555) 123-4567</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
