import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addproduct } from "../../redux/action";
import { IoMdHeartEmpty } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import AddReview from "../components/AddReview";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const ProductDetail = () => {
  const navigate = useNavigate();
  var { id } = useParams();
  id = parseInt(id);

  const [activeTab, setActiveTab] = useState("description");
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null); // Track the selected color
  const [mainImage, setMainImage] = useState(null);

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const venderId = localStorage.getItem("venderId");

  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const foundProduct = products.find((element) => id === element.id);
  const product = foundProduct;

  useEffect(() => {
    dispatch(addproduct());
    window.scrollTo(0, 0);
  }, [dispatch]);

  if (!product) return <div>Product not found</div>;

  const handleAddToCart = async (productId, venderId) => {
    setLoading(true);

    if (!token) {
      alert("You need to log in to add items to the cart.");
      setLoading(false);
      return;
    }

    // Data format for adding to cart
    const data = {
      color: selectedColor,
      size: "L",
      configurations: selectedPrice.configurations.map((config) => ({
        type: config.configuration_name,
        name: config.configuration_value,
      })),
      quantity: "1",
      old_price: selectedPrice.old_price,
      sale_price: selectedPrice.sale_price,
    };
    console.log("the data cart is ", data);
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/cart/user_id_${userId}/vendor_id_${venderId}/product_id_${productId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Product added to cart successfully!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleWishlist = async (productId, venderId) => {
    const data = {
      color: selectedColor,
      size: "L",
      configurations: selectedPrice.configurations.map((config) => ({
        type: config.configuration_name,
        name: config.configuration_value,
      })),

      old_price: selectedPrice.old_price,
      sale_price: selectedPrice.sale_price,
    };
    console.log("the data Wishlist  is ", data);
    try {
      await axios.post(
        `https://uvfolderking.com/suresop/api/wishlist/user_id_${userId}/vendor_id_${venderId}/product_id_${productId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Product added to wishlist!");
    } catch (error) {
      console.error("Error in addWishlist", error);
      alert("Select the color of item to add in Wishlist!");
    }
  };

  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-yellow-400" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-yellow-400" />;
      } else {
        return <FaRegStar key={index} className="text-yellow-400" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };
  const selectedPrice =
    product.prices.find((price) => price.color_name === selectedColor) ||
    product.prices[0];
  console.log(selectedPrice);
  function setProductDetails(value) {
    setSelectedColor(value);
    setMainImage(null);
  }
  // Find the selected price based on the selected color

  const mainImageSrc =
    mainImage ||
    `https://uvfolderking.com/suresop/storage/app/public/${selectedPrice.images[0].image_path}`; // Set default image
  return (
    <div className="">
      <div className="flex flex-col relative ml-6 mr-6">
        <div className="flex flex-col md:flex-row  rounded-lg overflow-hidden relative">
          <div className="w-full sticky bg-green-50 top-10 h-[700px] md:w-1/2 sm:p-8 ">
            <img
              src={mainImageSrc}
              // src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
              alt={product.name}
              className="w-[630px] h-[500px] object-cover rounded-lg border-2 border-blue-500"
            />
            <div className="flex overflow-x-auto mt-2 space-x-2">
              {/* Enable horizontal scrolling and add spacing */}
              {selectedPrice.images.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={`https://uvfolderking.com/suresop/storage/app/public/${image.image_path}`}
                  alt={product.name}
                  onClick={() =>
                    setMainImage(
                      `https://uvfolderking.com/suresop/storage/app/public/${image.image_path}`
                    )
                  }
                  className="w-[120px] h-[130px] object-cover rounded-lg border-2 border-transparent hover:border-[#b22222] cursor-pointer" // Add cursor pointer for better UX
                />
              ))}
            </div>
          </div>

          <div className="w-full md:w-1/2  lg:p-4 flex flex-col sm:pt-10">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-xl md:text-2xl font-bold mt-5 md:mb-4 sm:mt-10 ">
                  {product.name}
                </h2>
              </div>
              <div
                onClick={() => handleWishlist(product.id, product.vendor_id)}
                className="text-[24px]  md:text-[30px] text-red-500"
              >
                <IoMdHeartEmpty />
              </div>
            </div>

            <div className="flex space-x-2 md:space-x-4 items-center h-[40px] md:h-[50px] mb-2">
              <p className="border-r-2 border-gray-300 pr-2 md:pr-4">
                {renderStars(product.rating)}
              </p>
              <a
                href="#productReview"
                className="text-blue-500 text-sm md:text-base cursor-pointer"
              >
                Add Review
              </a>
            </div>

            <p className="text-lg md:text-xl font-semibold mb-2 md:mb-2">
              MRP: ₹{selectedPrice.sale_price}
            </p>
            <p className="text-gray-500 mb-4">{product.description}</p>

            <div className="flex items-center pb-2 sm:pb-1 mb-4">
              <p className="font-semibold">Categories: </p>
              <p className="text-gray-500">categories</p>
            </div>

            <div className="flex flex-col border-b-2  space-y-6">
              <div className="flex items-center gap-4 ">
                <p className="font-semibold text-lg ">Color:</p>
                {product.prices.map((price, index) => (
                  <div key={index} className="flex items-center">
                    <button
                      onClick={() => {
                        setProductDetails(price.color_name);
                      }} // Set selected color
                      className={`${
                        selectedColor === price.color_name
                          ? "border-blue-600"
                          : "border-gray-400"
                      } border px-2 py-1 rounded-md cursor-pointer`}
                    >
                      {price.color_name}
                    </button>
                  </div>
                ))}
              </div>

              <div>
                {selectedPrice.configurations.map((config, configIndex) => (
                  <div
                    key={configIndex}
                    className="flex  gap-5 items-center mb-3"
                  >
                    <h3 className="font-semibold w-32 text-lg">
                      {config.configuration_name}:
                    </h3>
                    <input
                      type="text"
                      value={config.configuration_value}
                      readOnly
                      className="border w-24 hover:border-blue-500 text-center px-2 py-1 rounded-md cursor-pointer "
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="hidden md:flex items-center h-[40px] md:h-[50px] mt-6 space-x-2 md:space-x-4 flex-wrap">
              <button className="bg-blue-500 text-white px-4 py-1 rounded-full hover:bg-blue-600 transition-colors sm:px-5 sm:py-2">
                Buy Now
              </button>

              <button
                disabled={loading}
                onClick={() => handleAddToCart(product.id, product.vendor_id)}
                className="bg-blue-500 text-white px-4 py-1 rounded-full hover:bg-blue-600 transition-colors sm:px-5 sm:py-2"
              >
                {loading ? "Adding..." : "Add To Cart"}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col  bg-gray-100 rounded-xl">
          <div className="ml-10 mb-5">
            <div className="mt-5 font-bold">
              <h2>Reviews Summary by AI:</h2>
            </div>

            <div className="flex flex-col mt-2 mr-4">
              <h2 className="font-bold">Summary:</h2>
              <p className="text-gray-500">
                This chair is attractive and easy to set up but it may be too
                stiff for some people's comfort...
              </p>
            </div>

            <div className="flex flex-col mt-5">
              <h2 className="font-bold">Pros:</h2>
              <ul className="text-gray-500">
                <li>Attractive</li>
                <li>Easy to set up</li>
                <li>Great Quality</li>
                <li>Enhancer for any Space</li>
              </ul>
            </div>

            <div className="flex flex-col mt-5">
              <h2 className="font-bold">Cons:</h2>
              <ul className="text-gray-500">
                <li>Stiff</li>
                <li>Lightweight Arm Cushions</li>
                <li>Troubles with Woodpanel Screws</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:m-4">
          <div className="flex space-x-4 mb-5 border-b-2 lg:mt-4">
            <button
              className={`py-2 px-4 ${
                activeTab === "description"
                  ? "border-black border-b-2 font-semibold"
                  : ""
              }`}
              onClick={() => setActiveTab("description")}
            >
              Description
            </button>

            <button
              className={`py-2 px-4 ${
                activeTab === "details"
                  ? "border-black border-b-2 font-semibold"
                  : ""
              }`}
              onClick={() => setActiveTab("details")}
            >
              Details
            </button>
          </div>

          <div>
            {activeTab === "description" && (
              <div className="m-4">
                <h3 className="font-bold">Product Description</h3>
                <p>{product.description}</p>
              </div>
            )}
            {activeTab === "details" && (
              <div className="m-4">
                <h3 className="font-bold">Product Details</h3>
                <p>Dimensions: {product.dimensions}</p>
                <p>Weight: {product.weight}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddReview />
      <ProductCard />

      {/* for mobile view */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-2 md:hidden flex justify-between items-center">
        <button className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors sm:px-10 sm:py-2">
          Buy Now
        </button>
        <button
          disabled={loading}
          onClick={() => handleAddToCart(product.id, product.vendor_id)}
          className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors sm:px-10 sm:py-2"
        >
          {loading ? "Adding..." : "Add To Cart"}
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
