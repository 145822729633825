import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">About Us</h1>
      
      <p className="text-lg text-gray-700 text-center mb-12">
        Welcome to Suresop, your ultimate destination for discovering unique products from a variety of independent vendors across different categories. We are a multi-vendor eCommerce platform that connects passionate entrepreneurs and skilled artisans with customers who value quality, innovation, and diversity. Our goal is to provide an exceptional shopping experience while supporting small businesses and independent creators.
      </p>

      <div className="text-center mb-8">
        <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          At Suresop, our mission is twofold: to empower vendors by giving them a robust, scalable platform to showcase their products, and to offer customers an unparalleled shopping experience with access to diverse, high-quality goods. We believe in creating a marketplace where innovation, creativity, and entrepreneurial spirit can thrive, providing an ecosystem where vendors and customers can connect in meaningful ways.
        </p>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-3xl font-semibold mb-4">What We Offer</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Our platform offers an extensive range of products, from handmade crafts to cutting-edge electronics, all sourced from independent vendors. Whether you're a customer looking for something unique or a vendor seeking a platform to grow your business, Suresop is built to meet your needs.
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          <li><strong>Wide Variety of Products:</strong> With thousands of products across different categories, there's something for everyone.</li>
          <li><strong>Safe and Secure Shopping:</strong> We prioritize your privacy and security, ensuring that all transactions are protected.</li>
          <li><strong>Fast and Reliable Shipping:</strong> We partner with trusted logistics providers to ensure timely deliveries.</li>
          <li><strong>24/7 Customer Support:</strong> Our dedicated customer support team is here to help you with any inquiries or issues.</li>
        </ul>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold mb-4">For Our Vendors</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          At Suresop, we believe in empowering vendors by providing them with a platform that’s not only easy to use but also rich in features to help them grow their business. Whether you're an established business or a budding entrepreneur, we offer tools and services designed to help you succeed.
        </p>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          As a vendor, you'll enjoy:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          <li><strong>Comprehensive Seller Dashboard:</strong> Manage your inventory, track orders, and view your sales analytics in real-time.</li>
          <li><strong>Dedicated Support:</strong> Our vendor support team is here to assist you every step of the way, ensuring you have all the tools you need to succeed.</li>
          <li><strong>Flexible Shipping Options:</strong> Customize your shipping methods and rates, or take advantage of our logistics partnerships for hassle-free shipping.</li>
          <li><strong>Marketing and Promotion:</strong> Benefit from our platform’s built-in marketing tools, including featured listings, discounts, and promotional campaigns to boost your visibility.</li>
          <li><strong>Low Fees:</strong> We offer competitive commission rates to ensure you keep more of your earnings.</li>
        </ul>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold mb-4">For Our Customers</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          We know that as a customer, you're looking for a shopping experience that’s both easy and rewarding. At Suresop, we pride ourselves on providing a seamless online shopping experience, offering a wide range of products from trusted vendors.
        </p>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          As a customer, you'll enjoy:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          <li><strong>Curated Products:</strong> Discover a handpicked selection of products from reliable vendors, all verified for quality and authenticity.</li>
          <li><strong>Easy Returns:</strong> Our hassle-free return policy ensures that if you're not satisfied with your purchase, we’ve got you covered.</li>
          <li><strong>Secure Payments:</strong> We use advanced encryption technologies to ensure your personal and payment information is always protected.</li>
          <li><strong>Community-Driven Marketplace:</strong> By shopping with us, you're supporting small businesses and independent vendors from around the world.</li>
        </ul>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold mb-4">Meet the Team</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Our team consists of experienced professionals from a variety of fields, including technology, design, marketing, and customer service. We are united by a shared passion for creating a platform that not only provides outstanding service but also supports small businesses and vendors around the globe.
        </p>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto mt-4">
          From our developers, who are constantly enhancing the platform's features, to our customer support team, who ensure that vendors and customers alike have a positive experience, we are committed to building a thriving marketplace.
        </p>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold mb-4">Join Us Today</h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
          Whether you're a vendor looking to expand your reach or a customer searching for unique, high-quality products, Suresop is the place for you. We invite you to explore our marketplace, discover amazing products, and join a growing community of entrepreneurs and shoppers.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
