import React from "react";

import Categories from "../components/Categories";
import Carousel from "../components/Carousel";
import ProductCard from "../components/ProductCard";
import Carousel2 from "./../components/Carousel2";
import ProductCardTwo from "../components/ProductCardTwo";
import BrandsInFocus from "../components/BrandsInFocus";

import TrendingOffer from "../components/TrendingOffers";
import SuresopSetting from "../components/SuresopSetting";
import ImageGallery from "../components/ImageGallery";
import ImageGalleryTwo from "../components/ImageGallaryTwo";

const Home = () => {
  return (
    <>
      <Categories />
      <Carousel />
      <ProductCard />
      <ImageGallery />
      <Carousel2 />
      <ProductCardTwo />
      <BrandsInFocus />
      <ImageGalleryTwo />
      {/* <TrendingOffer /> */}
      <SuresopSetting />
    </>
  );
};

export default Home;
