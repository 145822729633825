import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import image from "../../images/th.jpeg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, userAddresses } from "../../../redux/action";
import { useLocation } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoCalendarClear } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { PiArrowElbowUpLeftBold } from "react-icons/pi";
import Become from "../../images/Become.png";
import Banner from "../../images/Banner1.jpg";

const OrderDetails = () => {
  const { id } = useParams(); // Get the id from the URL
  const orderId = parseInt(id);
  const [userOrderAddress, SetOrderAddress] = useState([]);
  const [fulladdress, setFullAddress] = useState("");
  const [nearAddress, setNearbyAddress] = useState("");

  const navigate = useNavigate();
  const steps = [
    { label: "Order Confirmed", date: "Tue, 3rd Dec", key: "confirmed" },
    { label: "placed", date: "Thu, 5th Dec", key: "placed" },
    { label: "Out for delivery", date: "Sat, 7th Dec", key: "outForDelivery" },
    { label: "Delivery", date: "Expected by Sun, 8th", key: "delivered" },
  ];

  const location = useLocation();
  const { productData, orderDetail } = location.state;
  console.log("receiver data is ", productData, orderDetail);

  const address = useSelector((state) => state.userAddresses);
  const dispatch = useDispatch();
  console.log("the redux address is is is address", address);

  console.log("the redux address is is is address", address);
  const orderData = orderDetail.find((item) => item.id === orderId);
  console.log("found product in order detail", orderData);

  // const orderAddress = address?.addresses?.filter(
  //   (item) => item.id === orderData.shipping_address_id
  // );

  // console.log("found address", orderAddress);
  // const addresses=address.addresses.find((item) => item.id === product.
  // shipping_address_id)
  // console.log(" found address",addresses);

  useEffect(() => {
    dispatch(userAddresses());
    dispatch(getOrders());
  }, [dispatch]);
  useEffect(() => {
    if (orderData && address?.addresses) {
      const orderAddress = address.addresses.filter(
        (item) => item.id === orderData.shipping_address_id
      );
      SetOrderAddress(orderAddress[0]);
      setFullAddress(orderAddress[0].full_address.slice(0, 19));
      setNearbyAddress(orderAddress[0].near_by_address.slice(0, 19));
      console.log("found address", orderAddress[0]);
    }
  }, [orderData, address]);

  const getStepClass = (stepKey) => {
    const stepOrder = steps.map((step) => step.key);
    const currentIndex = stepOrder.indexOf(orderData.status);
    const stepIndex = stepOrder.indexOf(stepKey);

    if (stepIndex <= currentIndex) return "bg-green-500 text-white";
    if (stepIndex === currentIndex + 1) return "bg-gray-300 text-gray-500";
    return "bg-gray-100 text-gray-400";
  };

  // If no product is found, show a message
  if (!productData) {
    return <div className="p-6">Order not found</div>;
  }

  const handleProductDetail = (id) => {
    navigate(`/products/${id}`);
  };
  return (
    <div className="lg:p-5 sm:p-5 bg-[#E8F1F9]">
      <div className="flex flex-row ">
        <div className="w-full">
          {/* <h1 className="flex justify-center text-3xl font-bold lg:mb-14 lg:mt-5">
            Order Details
          </h1> */}
          <div className="flex flex-col justify-center items-center w-full ">
            <div className="flex pl-4 bg-white text-black rounded-sm py-4  mb-1 shadow-sm w-full ">
              <div className="border-r-[2px] border-gray-200 ">
             
                
                  <div className="flex  mt-1">
                    <div className="lg:mr-10">
                      <p className="text-gray-500">Customer Details</p>
                      <p className="font-semibold">
                        {userOrderAddress.full_name}
                      </p>
                    </div>
                    <div className="mr-8">
                      <p className="text-gray-500">Contact Number</p>
                      <p className="font-semibold">
                        {userOrderAddress.mobile_number}
                      </p>
                    </div>

                    <div>
                      <div className="pr-5">
                        <p className="text-gray-500">Alt. Contact</p>

                        {userOrderAddress.alternate_mobile_number ? (
                          <p className="font-semibold">
                            {userOrderAddress.alternate_mobile_number}
                          </p>
                        ) : (
                          <p>...</p>
                        )}
                      </div>
                    </div>
                  </div>
                
                {/* Order related */}
                <div className="flex text-gray-500 text-sm font-medium mt-3 lg:mr-10">
                  <div className="lg:mr-10">
                    <span className="flex">
                      <IoCalendarClear className="mt-1 mr-2" />
                      10-Dec-2024
                    </span>
                    <span className="ml-6">
                      Shipping Method: Standart Delivery
                    </span>
                  </div>
                  <div className="ml-4">
                    <span className="flex">
                      <FiShoppingCart className="mt-1 mr-2" />
                      Order Id {orderData.order_id}
                    </span>
                    <span className="ml-6">{}</span>
                  </div>
                  <div className="ml-4">
                    <div className="flex pt-5">
                      <span className="flex text-yellow-400">
                        <FaCaretRight className="mt-1 mr-1" />
                        {orderData.payment_type}
                      </span>
                      <span className="text-[#34C759] ml-2 mr-2">
                        ₹ {orderData.total_price}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Address */}
              <div className="flex flex-col mx-4">
                <div className="flex mb-4">
                  <div className="mr-6">
                    <p className="text-gray-500">Address</p>
                    <p className="font-semibold">{fulladdress}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Nearby Landmark</p>
                    <p className="font-semibold">{nearAddress}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-6">
                    <p className="text-gray-500">City</p>
                    <p className="font-semibold">{userOrderAddress.city}</p>
                  </div>
                  <div>
                    <p className="text-gray-500">Pincode</p>
                    <p className="font-semibold">{userOrderAddress.pin_code}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="delivery-instruction flex justify-center items-center w-full bg-red-100 rounded-sm py-1 px-8 mt-1 text-center gap-2">
              <TbTruckDelivery className="text-bold " />
              <p>
                "Delivery Instructions : Kindly call us when you arive to ensure
                a smooth and timely handover the delivery"
              </p>
            </div>
          </div>

          <div className="border p-4 mb-2 rounded-sm shadow-sm  bg-white mt-2">
            <div className="flex justify-between ">
              <div
                className="flex gap-6"
                onClick={() => handleProductDetail(orderData.product_id)}
              >
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${orderData.image_path}`}
                  alt={orderData.product_name}
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <div className="pl-2">
                  <h2 className="font-bold text-lg">
                    {orderData.product_name}
                  </h2>
                  <p className="text-gray-500">Color: {orderData.color}</p>
                  <p className="text-gray-500">size: {orderData.size}</p>
                  <p className="text-gray-500">
                    Price: ₹ {orderData.total_price}
                  </p>
                </div>
              </div>
              <div className="flex mt-4 mr-2">
                {steps.map((step, index) => (
                  <div key={step.key} className="flex flex-col items-center">
                    {/* Icon */}

                    <div className="ml-4">
                      <p
                        className={`font-semibold ${
                          getStepClass(step.key).includes("green")
                            ? "text-green-500"
                            : "text-gray-500"
                        }`}
                      >
                        {step.label}
                      </p>
                      {/* <p className="text-sm text-gray-500">{step.date}</p> */}
                    </div>
                    <div className="flex items-center">
                      <div
                        className={`w-5 h-5 rounded-full border-2 ${getStepClass(
                          step.key
                        )} flex items-center justify-center`}
                      >
                        {index ===
                        steps.map((s) => s.key).indexOf(orderData.status) ? (
                          <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
                        ) : null}
                      </div>

                      {/* Horizontal Line */}
                      {index < steps.length - 1 && (
                        <div
                          className={`h-0.5 w-24 ${
                            getStepClass(step.key).includes("green")
                              ? "bg-green-500"
                              : "bg-gray-300"
                          }`}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <p className="text-gray-500">Order Id:{orderData.order_id}</p>
                <p className="text-gray-500">Status:{orderData.status}</p>
                <p className="text-gray-500">Quantity:{orderData.quantity}</p>
                {/* <p>Order Year: {orderData.created_at}</p> */}
              </div>
            </div>
          </div>
          <div className="w-full h-[230px] bg-white shadow-lg">
            <img src={Banner} alt="" className="w-full  h-[230px]" />
          </div>
        </div>

        <div className="flex flex-col w-[450px] h-[594px] bg-white shadow-sm rounded-sm hidden md:inline  ml-3 ">
          <div>
            {" "}
            <img src={Become} alt="" className="w-[450px] h-[500px] p-2" />
          </div>

          <div className="w-full flex justify-center items-center align-center gap-2 mt-2 px-2">
            <button
              className="w-full flex items-center justify-center bg-blue-500 text-white py-2 h-[80px] rounded-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 animated-bg "
              onClick={() => navigate("")}
            >
              <PiArrowElbowUpLeftBold className="mr-2 text-xl " />
              <p className="text-2xl font-semibold">Continue Shopping</p>
            </button>
          </div>

          <style jsx>
            {`
              .animated-bg {
                animation: colorChange 8s infinite,
                  fadeInOut 2s infinite alternate;
              }

              @keyframes colorChange {
                0% {
                  background-color: #3498db; /* Blue */
                }
                25% {
                  background-color: #e74c3c; /* Red */
                }
                50% {
                  background-color: #2ecc71; /* Green */
                }
                75% {
                  background-color: #f1c40f; /* Yellow */
                }
                100% {
                  background-color: #3498db; /* Blue (loop back) */
                }
              }

              @keyframes fadeInOut {
                0% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.5;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          </style>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
