import React,{useEffect} from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
      
      <div className="space-y-8">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to Suresop. We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This privacy policy explains how we collect, use, and protect your data.
          </p>
        </section>

        {/* Information Collection */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
          <p>
            We collect information that you provide to us directly, such as when you create an account, place an order, or contact customer service. This includes:
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Your name, email address, and contact details</li>
            <li>Shipping and billing information</li>
            <li>Payment details (collected securely by our payment processor)</li>
            <li>Order history and preferences</li>
          </ul>
        </section>

        {/* How We Use Your Information */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
          <p>
            We use the information you provide to:
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Process and fulfill your orders</li>
            <li>Provide customer service and support</li>
            <li>Send you order updates and promotional materials</li>
            <li>Improve our website and services</li>
            <li>Comply with legal requirements</li>
          </ul>
        </section>

        {/* Data Security */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
          <p>
            We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. This includes encryption of sensitive data and secure servers. However, please note that no method of transmission over the internet is 100% secure.
          </p>
        </section>

        {/* Cookies */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Cookies</h2>
          <p>
            Our website uses cookies to improve your browsing experience. Cookies are small data files stored on your device. They help us remember your preferences and track your activity on our site. You can disable cookies through your browser settings, but this may affect your ability to use certain features of the website.
          </p>
        </section>

        {/* Third-Party Services */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
          <p>
            We may share your information with third-party service providers that help us operate our website and fulfill orders, such as payment processors and shipping companies. These providers are contractually obligated to handle your information securely and in compliance with applicable laws.
          </p>
        </section>

        {/* Your Rights */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information at any time. You can do this by logging into your account or contacting our customer service team. If you wish to opt-out of marketing communications, you can unsubscribe by following the instructions in our emails.
          </p>
        </section>

        {/* Updates to Policy */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Updates to This Policy</h2>
          <p>
            We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant updates by posting the revised policy on our website or by other means.
          </p>
        </section>

        {/* Contact Us */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions or concerns about our privacy policy, or if you would like to exercise your rights, please contact us at:
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Email: support@suresop.com</li>
            <li>Phone: +91 8346345345</li>
            <li>Address: 1234 Main St, City, Country</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
