import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import image from "../../images/MYPIC.png";
import profile from "../../images/profiledummy.png";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "./../../../redux/action";
import { RiLogoutCircleRLine } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserAccount = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeItem, setActiveItem] = useState("");
  const user = { image: image, dummy: profile };
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  console.log(token);
  const [mobile, isMobileView] = useState(false);
  const { showUserAccount } = props;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (window.innerWidth < 420) {
      isMobileView(true);
    }
  }, []);

  const userDetail = useSelector((state) => state.userProfile);
  console.log("data is", userDetail["User Profile"]);

  useEffect(() => {
    UserProfile();
  }, []);

  // console.log("the redux profile in account now  is ", userProfile);
  const UserProfile = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(
        `https://uvfolderking.com/suresop/api/user/profile/${userId}`,
        config
      );
      setUserProfile(response.data["User Profile"]);
      console.log("USERaCCOUNT", response.data["User Profile"]);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(error);
        console.error("Unauthorized: Invalid token or session expired.");
        // Optional: dispatch a logout or token refresh action
      } else {
        console.error("Error fetching user profile:", error);
      }
    } finally {
      setLoading(false);
    }
  };
  function setUserAccount() {
    showUserAccount();
  }

    const handleItemClick = (item) => {
    setActiveItem(item); // Set clicked item as active
  };
  return (
    <div
      className={
        "absolute flex flex-col pl-5 w-[-20px] sticky top-[98px] z-20 p-5 shadow-lg bg-white max-sm:fixed top-[100px] flex flex-col pl-5 w-[250px] sticky top-24 z-30 p-5 shadow-lg bg-white max-sm:fixed z-30"
      }
    >
      {mobile ? <MdClose onClick={setUserAccount} /> : ""}
      {loading ? (
        <div className="flex flex-col items-center gap-5 p-2 shadow-sm rounded-md">
          {/* User Profile Skeleton */}
          <div className="flex items-center gap-5 p-2 shadow-sm rounded-md">
            <Skeleton circle={true} height={64} width={64} />
            <div className="text-sm">
              <Skeleton width={50} height={16} />
              <Skeleton width={120} height={20} />
            </div>
          </div>

          {/* MY ORDERS Section Skeleton */}
          <div className="">
            <Skeleton width={200} height={20} />
            <div className=" space-y-2">
              <Skeleton width={150} height={18} />
            </div>
          </div>

          {/* ACCOUNT SETTINGS Section Skeleton */}
          <div className="">
            <Skeleton width={200} height={20} />
            <ul className="mt-3 space-y-2">
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} width={180} height={16} />
              ))}
            </ul>
          </div>

          {/* PAYMENTS Section Skeleton */}
          <div className="mt-5">
            <Skeleton width={200} height={20} />
            <ul className="mt-3 space-y-2">
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} width={150} height={16} />
              ))}
            </ul>
          </div>

          {/* MY STUFF Section Skeleton */}
          <div className="mt-5">
            <Skeleton width={200} height={20} />
            <ul className="mt-3 space-y-2">
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} width={170} height={16} />
              ))}
            </ul>
          </div>

          {/* Logout Skeleton */}
          <div className="mt-5">
            <Skeleton width={100} height={18} />
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-5 p-2 shadow-sm rounded-md">
          <img
            src={user.dummy}
            alt={user.dummy}
            className="w-16 h-16 rounded-full"
          />
          <div className="text-sm">
            <p className="text-gray-500">Hello,</p>
            <p className="font-semibold">
              {userProfile.first_name} {userProfile.last_name}
            </p>
          </div>
        </div>
      )}

      <div className="mt-5">
        <Link to="/account/myorders">
        <h2
            className={`text-lg font-semibold mb-3 border-b pb-2 ${
              activeItem === "myorders" ? "text-[#b22222]" : ""
            }`}
            onClick={() => handleItemClick("myorders")}
          >
            MY ORDERS
          </h2>
        </Link>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">ACCOUNT SETTINGS</h2>
        <ul className="space-y-2">
          <Link to="/account/account_details">
            <li className="hover:text-[#b22222]  mb-2 cursor-pointer">
              Profile Information
            </li>
          </Link>
          <Link to="/account/addresses">
            <li className="hover:text-[#b22222]  mb-2 cursor-pointer">
              Manage Addresses
            </li>
          </Link>
          <li className="border-b pb-2 hover:text-[#b22222]  cursor-pointer">
            PAN Card Information
          </li>
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">PAYMENTS</h2>
        <ul className="space-y-2">
          <li className="hover:text-[#b22222]  cursor-pointer">Gift Cards</li>
          <li className="hover:text-[#b22222]  cursor-pointer">Saved UPI</li>
          <li className="border-b pb-2 hover:text-[#b22222]  cursor-pointer">
            Saved Cards
          </li>
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">MY STUFF</h2>
        <ul className="space-y-2">
          <li className="hover:text-[#b22222] cursor-pointer">
            My Reviews & Ratings
          </li>
          <Link to="/account/wishlist-products">
            <li className="hover:text-[#b22222]  mt-2  cursor-pointer">
              My Wishlist
            </li>
          </Link>
          <li className="border-b pb-2 hover:text-[#b22222]  cursor-pointer">
            My Coupons
          </li>
        </ul>
      </div>

      <div
        onClick={() => {
          localStorage.removeItem("token");
          // window.location.reload();
          navigate("/");
        }}
        className=" flex justify-center items-center mt-5  text-lg border-b pb-2 font-semibold hover:text-red-500 cursor-pointer"
      >
        <RiLogoutCircleRLine className="mr-2 " /> LOGOUT
      </div>
    </div>
  );
};

export default UserAccount;
