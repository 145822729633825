import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineLocalShipping } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { CiHeart } from "react-icons/ci";

const NavUpper = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="flex md:flex-row md:justify-between sm:justify-between justify-between items-center p-2 ml-8 mr-8 border-b border-gray-200 bg-white text-gray-800">
      {/* Shipping Information */}
      <div className="text-[13px] text-[#b22222] hidden sm:inline">
        <p>FREE Express Shipping On Orders ₹99+</p>
      </div>

      {/* Navigation Links */}
      <div className="flex space-x-8">
        {/* Store Locator */}
        <Link to="/ourstores">
          <div
            className={`flex items-center space-x-2 cursor-pointer ${
              isActive("/ourstores") ? "text-[#b22222]" : "hover:text-black-500"
            }`}
          >
            <IoLocationOutline className="text-[17px]" />
            <p className="text-[13px]  hidden md:inline cursor-pointer">Our Stores</p>
          </div>
        </Link>

        {/* Track Orders */}
        <Link to="/account/myorders">
  <div
    className={`flex items-center space-x-2 cursor-pointer ${
      isActive("/account/myorders") // Ensure this matches the link target
        ? "text-[#b22222]" // Active state style
        : "hover:text-black-500"
    }`}
  >
    <MdOutlineLocalShipping className="text-xl" />
    <p className="text-[13px] hidden md:inline cursor-pointer">Track Your Orders</p>
  </div>
</Link>

        {/* Help */}
        <Link to="/help">
          <div
            className={`flex items-center space-x-2 cursor-pointer ${
              isActive("/help") ? "text-[#b22222] " : "hover:text-black-500"
            }`}
          >
            <IoIosHelpCircleOutline className="text-xl" />
            <p className="text-[13px] hidden md:inline cursor-pointer">Help</p>
          </div>
        </Link>

        {/* Wishlists */}
        <Link to="/account/wishlist-products">
          <div
            className={`flex items-center  space-x-2 cursor-pointer ${
              isActive("/account/wishlist-products")
                ? "text-[#b22222] "
                : "hover:text-black-500"
            }`}
          >
            <CiHeart className="text-xl" />
            <p className="text-[13px] hidden md:inline cursor-pointer">Wishlists</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NavUpper;
