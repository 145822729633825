import axios from "axios";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, Link } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error,setError]=useState();
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/categories/getallcategory`
      );
      setCategories(response.data);
      console.log("category",response.data)
      setLoading(false);
    } catch (error) {
      if (error.request) {
        // This block handles network errors
        setError('Network error. Please check your internet connection or try again later.');
        setLoading(true);  // Setting loading to true during network error
      } else if (error.response) {
        const status = error.response.status;
    
        if (status === 404) {
          setError('Data not found.');
        } else if (status === 500) {
          setError('Server is currently unavailable. Please try again later.');
        } else if (status === 422) {
          setError('Validation failed. Please check the input and try again.');
        } else if (status === 401) {
          setError('Unauthorized. Please log in to access this resource.');
        } else if (status === 403) {
          setError('Forbidden. You do not have permission to access this resource.');
        } else if (status === 400) {
          setError('Bad Request. Please ensure the request is properly formatted.');
        } else if (status === 408) {
          setError('Request Timeout. Please try again.');
        } else {
          setError(`Unexpected error occurred. Status code: ${status}`);
        }
      } else {
        // Handles any other unexpected errors
        setError('An unexpected error occurred.');
      }
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const handlecategoryProductList = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  return (
    <div className="bg-red-50 xs:p-2 sm:p-4 md:p-6 lg:pb-3 lg:pt-2 relative">
      {loading ? (
        <Slider {...settings}>
          {[...Array(8)].map((_, index) => (
            <div
              key={index}
              className="flex flex-col items-center space-y-2 p-2"
            >
              <div className="flex justify-center">
                <Skeleton
                  circle
                  width={80}
                  height={80}
                  baseColor="#cbd5e1" // Background color
                  // highlightColor="#bae6fd"
                  className=" w-14 h-14 xs:w-12 xs:h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-20 lg:h-20"
                />
              </div>
              <div className="text-center">
                <Skeleton width={80} height={20}  baseColor="#cbd5e1"/>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...settings}>
          {categories.map((category) => (
            <div
              key={category.id}
              onClick={() => handlecategoryProductList(category.id)}
              className="flex flex-col items-center space-y-2 p-2"
            >
              <Link to={`/category/${category.id}`}>
                <div className="flex justify-center">
                  <div className="rounded-full overflow-hidden border-2 border-[#b22222] w-14 h-14 xs:w-12 xs:h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 relative">
                    <img
                    //src={`https://uvfolderking.com/suresop/${category.image}`}

                      src={`https://uvfolderking.com/admin/storage/app/public/${category.image}`}
                      alt={category.name}
                      className="object-cover w-full h-full rounded-full"
                    />
                  </div>
                </div>
                <div className="text-center text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                  <h6>{category.name}</h6>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <button
    className="hidden sm:inline absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-gray-200 text-[#b22222] rounded-full hover:bg-[#b22222] hover:text-white transition-colors duration-300 z-10"
    onClick={onClick}
  >
 &#10095;  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    className="hidden sm:inline absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-gray-200 text-[#b22222] rounded-full hover:bg-[#b22222] hover:text-white transition-colors duration-300 z-10"
    onClick={onClick}
  >
 &#10094;  
 </button>
);

export default Categories;
