import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserAccount from "./UserAccount";
import { MdOutlineMyLocation } from "react-icons/md";

const UserEditAddress = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const addId = parseInt(id);

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    full_name: "full name ",
    mobile_number: "",
    pin_code: "",
    full_address: "",
    state: "",
    city: "",
    near_by_address: "",
    alternate_mobile_number: "",
    deliveryOption: "home",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const address = useSelector((state) => state.userAddresses);
  // console.log("add in edit address", address);
 
 
 
  // Filter the address based on the addressId
  const findaddress = (addId) => {
    const userAdd = address.addresses?.filter((add) => {
      return addId === add.id;
    });
    return userAdd;
  };
  // console.log("typess", typeof addId);
  // console.log("add", findaddress(addId));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.full_name) errors.full_name = "Full name is required.";
    if (!/^[\d]{10}$/.test(formData.mobile_number))
      errors.mobile_number = "Valid mobile number is required.";
    if (!formData.pin_code) errors.pin_code = "Pin code is required.";
    if (!formData.full_address) errors.full_address = "Address is required.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const response = await axios.put(
        `https://uvfolderking.com/suresop/api/user/shipping-address/user_id_${userId}/address_id_${addId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/account/addresses");
    } catch (error) {
      console.error("The error in editing the address", error);
    }

    setFormData({
      full_name: "",
      mobile_number: "",
      pin_code: "",
      full_address: "",
      state: "",
      city: "",
      near_by_address: "",
      alternate_mobile_number: "",
      deliveryOption: "home",
    });
  };

  const handleLocationClick = () => {
    console.log("Using current location...");
  };

  return (
    <div>
      <div className="flex gap-5 lg:ml-10 ">
        <div>
          <UserAccount />
        </div>
        <div className="flex-grow p-5 mr-10 shadow-lg rounded-md">
          <h2 className="text-xl font-semibold pb-5">EDIT ADDRESS</h2>

          <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-xl p-8 bg-white shadow-lg rounded-lg">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">Edit Address</h2>
                <button
                  onClick={handleLocationClick}
                  className="flex items-center bg-blue-500 text-white px-4 py-2 rounded cursor-pointer"
                >
                  <MdOutlineMyLocation className="text-xl mr-2" />
                  <span>Use your Current Location</span>
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4">
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        className={`border p-2 w-full rounded ${
                          errors.full_name
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      />
                      {errors.full_name && (
                        <p className="text-red-500 text-sm">
                          {errors.full_name}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Mobile No"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleChange}
                        className={`border p-2 w-full rounded ${
                          errors.mobile_number
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      />
                      {errors.mobile_number && (
                        <p className="text-red-500 text-sm">
                          {errors.mobile_number}
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <textarea
                      cols={50}
                      rows={4}
                      placeholder="Enter your address"
                      name="full_address"
                      value={formData.full_address}
                      onChange={handleChange}
                      className={`border p-2 w-full rounded ${
                        errors.full_address
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.full_address && (
                      <p className="text-red-500 text-sm">
                        {errors.full_address}
                      </p>
                    )}
                  </div>

                  <input
                    type="text"
                    placeholder="Landmark"
                    name="near_by_address"
                    value={formData.near_by_address}
                    onChange={handleChange}
                    className="border p-2 w-full rounded border-gray-300"
                  />

                  <div className="flex gap-4">
                    <input
                      type="text"
                      placeholder="City/District/Town"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="border p-2 w-full rounded border-gray-300"
                    />
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="border p-2 w-full rounded border-gray-300"
                    />
                  </div>

                  <div className="flex gap-4">
                    <input
                      type="text"
                      placeholder="PinCode"
                      name="pin_code"
                      value={formData.pin_code}
                      onChange={handleChange}
                      className="border p-2 w-full rounded border-gray-300"
                    />
                    <input
                      type="text"
                      placeholder="Alternate Phone (optional)"
                      name="alternate_mobile_number"
                      value={formData.alternate_mobile_number}
                      onChange={handleChange}
                      className="border p-2 w-full rounded border-gray-300"
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Edit Address"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditAddress;
